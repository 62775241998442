/* You can add global styles to this file, and also import other style files */
@import "../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css";
@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-icons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-richtexteditor/styles/material.css';
@import '~@fortawesome/fontawesome-free/css/all.min.css';


* {
    font-family: "Montserrat", sans-serif;
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.table-header {
    background: #008B8B !important;
    text-align: center;
    font-size: 14px;
    color: white;
    font-weight: bold;
    font-family: 'Times New Roman', Times, serif;
}

.add-btn-panel {
    float: right;
    margin: 10px;
}

.mat-card-form {
    margin-top: 50px;
}

.custom-success-snackbar {
    margin-top: 50px !important;
    background-color: blue;
    text-align: center;
}

.btn1 {
    color: #ffffff;
    background: #008B8B !important;
    width: 140px;
    height: 35px;
    border-radius: 3px;
    margin-left: 30px;
    outline: none;
    border: none;
 }

 .btn2 {
    color: #ffffff;
    background: #008B8B !important;
    width: 140px;
    height: 35px;
    border-radius: 3px;
    margin-left: 30px;
    outline: none;
    border: none;
 }